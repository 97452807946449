import { ReactChakraSelect } from '@springcare/sh-component-library';
import keyBy from 'lodash/keyBy';

export const Select = ({ options, value, onChange, ...rest }) => {
  const optionsDictionary = keyBy(options, 'value');

  return (
    <ReactChakraSelect
      value={value ? optionsDictionary[value] : undefined}
      onChange={({ value }) => onChange(value)}
      options={options}
      {...rest}
      isMulti={false}
    />
  );
};
